<template>
  <div>
    <div>
      <CCard>
        <CCardHeader>
          <b>{{ scp.Name }}</b>
          <CSwitch
            class="float-right"
            size="sm"
            shape="pill"
            color="info"
            data-on="On"
            data-off="Off"
            :checked.sync="active"
            @update:checked="enableSCP"
            label-off="OFF"
            label-on="ON"
            :disabled="!$isAccountOwner(accountId)"
          />
        </CCardHeader>
        <CCardBody>
          {{ scp.Description }}
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import axios from "axios";

async function activateSCP(accountId, scp, active, accessToken) {
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken,
        "Content-Type": "application/json"
      }
    };
    let payload = {
      toggle: active ? "ON" : "OFF"
    };
    const URL =
      process.env.VUE_APP_BACKEND_BASEURL +
      "/accounts/" +
      accountId +
      "/scps/" +
      scp.Id +
      "/switch";
    const response = await axios.post(URL, payload, config);
    return true;
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("There was an error while processing your action");
  }
}

export default {
  name: "SCPItem",
  props: {
    scp: Object,
    accountId: String
  },
  data() {
    return {
      active: this.scp.Status === "Enabled",
      busy: {
        show: false
      }
    };
  },
  methods: {
    async enableSCP() {
      this.$emit("processing", true);
      try {
        let accessToken = await this.$auth.getAccessToken();
        let res = await activateSCP(
          this.accountId,
          this.scp,
          this.active,
          accessToken
        );
      } catch (e) {
        this.active = !this.active;
        this.$store.commit("showErrorModal", ["Error", e.message]);
      } finally {
        this.$emit("processing", false);
      }
    }
  }
};
</script>
