<template>
  <div>
    <CAlert show color="primary"
      ><b>{{ accountName }}</b> ({{ accountId }})</CAlert
    >
  </div>
</template>

<script>
export default {
  name: "AccountInfoBadge",
  props: ["accountId"],
  data() {
    return {
      accountName: undefined,
      unsubscribe: undefined
    };
  },
  async created() {
    if (this.$store.state.userinfo != undefined) {
      this.resolveAlias();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setUserInfoState") {
        if (state.userinfoReady) {
          this.resolveAlias();
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    resolveAlias() {
      console.log(this.accountId);
      this.accountName = this.$resolveAlias(this.accountId);
    }
  }
};
</script>
