<template>
  <div>
    <CAlert color="secondary" closeButton :show="true">
      (*) Please note that permission modifications
      (create/delete/update/assign/unassign) may take up to a few hours to apply
      in okta/aws
    </CAlert>
  </div>
</template>

<script>
export default {
  name: "AccountNoticeAlert",
  data() {
    return {};
  }
};
</script>
