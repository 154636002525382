<template>
  <div>
    <AccountInfoBadge :accountId="accountId" />
    <AccountNoticeAlert />
    <div v-if="!busy.loading">
      <CRow>
        <CCol sm="6" md="4" v-for="scp in listSCPs" :key="scp.Id">
          <SCPItem :scp="scp" :accountId="accountId" @processing="processing" />
        </CCol>
      </CRow>
    </div>
    <div v-else align="center">
      <img src="/img/tenor.gif" width="50" />
    </div>
  </div>
</template>

<script>
import AccountInfoBadge from "./AccountInfoBadge.vue";
import AccountNoticeAlert from "./AccountNoticeAlert.vue";
import axios from "axios";
import SCPItem from "./SCPItem.vue";

async function listAccountSCPs(accountId, accessToken) {
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken
      }
    };
    const URL =
      process.env.VUE_APP_BACKEND_BASEURL + "/accounts/" + accountId + "/scps";
    const response = await axios.get(URL, config);
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("There was an error while fetching your data");
  }
}

export default {
  name: "Tables",
  components: { SCPItem, AccountInfoBadge, AccountNoticeAlert },
  data() {
    return {
      accountId: this.$route.params.id,
      listSCPs: undefined,
      busy: {
        loading: true
      }
    };
  },
  async created() {
    var accessToken = await this.$auth.getAccessToken();
    try {
      let listSCPs = await listAccountSCPs(this.accountId, accessToken);
      this.busy.loading = false;
      this.listSCPs = listSCPs.Account.AccountScps.sort((a, b) => {
        return a.Name.localeCompare(b.Name, "en", { sensitivity: "base" });
      });
    } catch (err) {
      this.listSCPs = [];
      this.busy.loading = false;
      this.$store.commit("showErrorModal", ["Error", err.message]);
    }
  },
  methods: {
    async processing(active) {
      this.$store.commit("set", ["busymodalShow", active]);
    }
  }
};
</script>
